define("ember-svg-jar/inlined/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_1168_81)\"><path d=\"M164.88 129.21a66.999 66.999 0 0017.783-113.87 67.002 67.002 0 00-85.326 0 67 67 0 0017.783 113.87l-23.93 56A2 2 0 0093 188h94a1.993 1.993 0 001.672-.899 1.991 1.991 0 00.168-1.891l-23.96-56z\" fill=\"#FF4A7E\"/><path d=\"M85.14 72.56a30 30 0 10-55.61 22.53c3.31 8.17 2.58 10.72-5.18 24.81L23 101.74a11.53 11.53 0 00-12.33-10.67A11.52 11.52 0 000 103.4l2 27.86A11.542 11.542 0 0013.2 142a116.28 116.28 0 00-9.31 46h60c0-15.65 5.39-25.37 12.2-37.68C86 132.47 99.5 108 85.14 72.56zM170.93 77a1.997 1.997 0 011.89 1.332c.092.259.129.534.11.808a33.001 33.001 0 01-65.86 0 2.001 2.001 0 012-2.14h61.86zM197 62.46c14.912 0 27-12.191 27-27.23S211.912 8 197 8s-27 12.191-27 27.23 12.088 27.23 27 27.23z\" fill=\"#29194A\"/><path d=\"M194 48.33a2.996 2.996 0 01-2.14-.89l-10-10.09a3.009 3.009 0 01-.637-3.286 3.01 3.01 0 014.917-.954L194 41l14.86-15a3.012 3.012 0 114.28 4.24l-17 17.15a3.001 3.001 0 01-2.14.94zM77.52 81.49a2 2 0 012.64 1.31 21 21 0 01-39 15 2 2 0 011.08-2.74l35.28-13.57z\" fill=\"#fff\"/><path d=\"M170.199 148.478c4.507 4.507 11.812 4.508 16.317.004 4.505-4.505 4.503-11.81-.004-16.317l-4.695-4.695c-4.506-4.507-11.812-4.508-16.316-.004-4.505 4.505-4.503 11.81.003 16.317l4.695 4.695z\" fill=\"#29194A\"/></g><defs><clipPath id=\"clip0_1168_81\"><path fill=\"#fff\" d=\"M0 0h224v188H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "224",
      "height": "188",
      "viewBox": "0 0 224 188",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
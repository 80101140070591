define("ember-svg-jar/inlined/invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25.71 3.694c-.81-.195-1.65-.194-2.761-.194h-5.871c-1.409 0-2.572 0-3.519.077-.983.08-1.888.253-2.737.686a7 7 0 00-3.059 3.06c-.433.849-.605 1.753-.686 2.736C7 11.007 7 12.17 7 13.578v14.844c0 1.41 0 2.572.077 3.519.08.984.253 1.888.686 2.737a7 7 0 003.06 3.06c.848.432 1.753.604 2.736.685.947.077 2.11.077 3.519.077h7.844c1.409 0 2.572 0 3.519-.077.983-.08 1.888-.253 2.737-.686a7 7 0 003.059-3.059c.433-.85.605-1.753.686-2.737.077-.947.077-2.11.077-3.519v-12.87c0-1.112.001-1.952-.193-2.761a7.001 7.001 0 00-.839-2.024c-.435-.71-1.029-1.303-1.815-2.089l-2.331-2.331c-.786-.786-1.38-1.38-2.09-1.815a7 7 0 00-2.023-.838zM22.75 7h-5.6c-1.499 0-2.518.001-3.306.066-.767.063-1.16.176-1.433.316a3.5 3.5 0 00-1.53 1.53c-.139.273-.253.665-.315 1.432-.065.788-.066 1.807-.066 3.306v14.7c0 1.5.001 2.518.066 3.306.062.767.176 1.16.316 1.433a3.5 3.5 0 001.529 1.53c.274.14.666.253 1.433.315.788.065 1.807.066 3.306.066h7.7c1.499 0 2.518-.001 3.306-.066.767-.062 1.16-.176 1.433-.315a3.5 3.5 0 001.53-1.53c.139-.274.253-.666.315-1.433.065-.788.066-1.807.066-3.306v-12.6H28a5.25 5.25 0 01-5.25-5.25V7zm7.98 5.25c-.21-.249-.546-.591-1.178-1.223l-2.08-2.08c-.63-.63-.973-.967-1.222-1.177v2.73c0 .967.784 1.75 1.75 1.75h2.73z\" fill=\"#29194A\"/><path d=\"M15.75 21a1.75 1.75 0 100 3.5h10.5a1.75 1.75 0 100-3.5h-10.5zM15.75 28a1.75 1.75 0 100 3.5H21a1.75 1.75 0 100-3.5h-5.25z\" fill=\"#29194A\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
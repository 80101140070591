define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 2.5a5 5 0 100 10 5 5 0 000-10zm-6.25 5a6.25 6.25 0 1112.5 0 6.25 6.25 0 01-12.5 0z\" fill=\"currentColor\"/><path d=\"M7.5 4.375a.625.625 0 100 1.25.625.625 0 000-1.25zM8.125 7.5a.625.625 0 10-1.25 0V10a.625.625 0 101.25 0V7.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
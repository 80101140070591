define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.75 28a5.25 5.25 0 00-5.25 5.25c0 .967.784 1.75 1.75 1.75h17.5a1.75 1.75 0 001.75-1.75c0-2.9-2.35-5.25-5.25-5.25h-10.5zM7 33.25a8.75 8.75 0 018.75-8.75h10.5A8.75 8.75 0 0135 33.25c0 2.9-2.35 5.25-5.25 5.25h-17.5A5.25 5.25 0 017 33.25zM21 7a5.25 5.25 0 100 10.5A5.25 5.25 0 0021 7zm-8.75 5.25a8.75 8.75 0 1117.5 0 8.75 8.75 0 01-17.5 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25 8.334C15.796 8.334 8.334 15.796 8.334 25c0 9.205 7.462 16.667 16.666 16.667 9.205 0 16.667-7.462 16.667-16.667 0-9.204-7.462-16.666-16.667-16.666zM4.167 25C4.167 13.494 13.494 4.167 25 4.167c11.506 0 20.834 9.327 20.834 20.833 0 11.506-9.328 20.834-20.834 20.834S4.167 36.506 4.167 25z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25 14.583c1.15 0 2.083.933 2.083 2.083v6.25h6.25a2.083 2.083 0 010 4.167h-6.25v6.25a2.083 2.083 0 11-4.167 0v-6.25h-6.25a2.083 2.083 0 110-4.167h6.25v-6.25c0-1.15.933-2.083 2.084-2.083z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
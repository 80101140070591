define("ember-svg-jar/inlined/coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 6a3 3 0 013-3h4a3 3 0 01.79 5.895c.136.342.21.715.21 1.105 0 .535-.14 1.037-.385 1.471.191.123.367.266.525.427a5.028 5.028 0 00-1.38 1.452A.998.998 0 0011 13H7a1 1 0 100 2h4c.034 0 .068-.002.101-.005-.066.325-.101.66-.101 1.005 0 1.416.589 2.695 1.535 3.605A2.998 2.998 0 0110 21H6a3 3 0 01-1.615-5.529A2.987 2.987 0 014 14c0-.535.14-1.037.385-1.471a3.001 3.001 0 01.825-5.424A2.992 2.992 0 015 6zm5 3a1 1 0 110 2H6a1 1 0 110-2h4zm2-2H8a1 1 0 010-2h4a1 1 0 110 2zm-2 10a1 1 0 110 2H6a1 1 0 110-2h4z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 13a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-media/components/-private/media-query-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MediaQueryList {
    constructor(targetWindow, mediaQuery, listener) {
      var _this = this;

      this.nativeMediaQueryList = targetWindow.matchMedia(mediaQuery);
      this.active = true;

      this.listener = function () {
        _this.matches = _this.nativeMediaQueryList.matches;

        if (_this.active) {
          listener(...arguments);
        }
      };

      this.nativeMediaQueryList.addListener(this.listener);
      this.matches = this.nativeMediaQueryList.matches;
    }

    cancel() {
      this.active = false;
      this.nativeMediaQueryList.removeListener(this.listener);
    }

  }

  _exports.default = MediaQueryList;
});
define("ember-svg-jar/inlined/car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.454 15.632c1.005 0 1.819-.802 1.819-1.79s-.814-1.79-1.819-1.79c-1.004 0-1.818.802-1.818 1.79s.814 1.79 1.818 1.79zM16.545 15.632c1.004 0 1.818-.802 1.818-1.79s-.814-1.79-1.818-1.79-1.818.802-1.818 1.79.814 1.79 1.818 1.79z\" fill=\"#FF4A7E\"/><path d=\"M12.909 14.737H11.09c-.546 0-.91-.358-.91-.895s.364-.895.91-.895h1.818c.545 0 .909.358.909.895s-.364.895-.91.895z\" fill=\"#29194A\"/><path d=\"M22 13.842c0-1.79-1.09-3.4-2.727-4.116V8.474c0-2.506-2-4.474-4.546-4.474H9.273C6.727 4 4.727 5.968 4.727 8.474v1.252C3.091 10.442 2 12.053 2 13.842c0 2.147 1.545 3.937 3.636 4.384v1.88c0 .536.364.894.91.894h1.818c.545 0 .909-.358.909-.895v-1.79h5.454v1.79c0 .537.364.895.91.895h1.817c.546 0 .91-.358.91-.895v-1.879C20.454 17.78 22 15.99 22 13.842zM6.545 8.474c0-1.521 1.182-2.685 2.728-2.685h5.454c1.546 0 2.727 1.164 2.727 2.685v.894H6.545v-.894zm10.91 8.052H6.544c-1.545 0-2.727-1.163-2.727-2.684 0-1.52 1.182-2.684 2.727-2.684h10.91c1.545 0 2.727 1.163 2.727 2.684 0 1.521-1.182 2.684-2.727 2.684z\" fill=\"#29194A\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "accept_invite": {
      "accepted": {
        "body1": "Du har akseptert invitasjonen og din bruker har blitt opprettet!",
        "body2": "Administrator for bedriften kan nå bestille en bil til deg eller gi deg tilgang til en eksisterende bil. Bruk appen for å administrere bilen du har tilgang til som ansatt.",
        "title": "Bruker Opprettet"
      },
      "not_found": {
        "subtitle": "Prøv igjen eller kontakt kundeservice.",
        "title": "Fant ikke Invitasjonen"
      },
      "submit": "Bekreft",
      "title": "Opprett Bruker"
    },
    "account_info": {
      "button": "Endre",
      "edit_header": "Endre",
      "fields": {
        "address": "Adresse",
        "address_line1": "Adresselinje 1",
        "address_line2": "Adresselinje 2",
        "city": "By",
        "email": "E-post",
        "name": "Navn",
        "phone_number": "Telefonnummer",
        "zip_code": "Postnummer"
      },
      "header": "Om bedriften",
      "submit": "{submitting, select, true {Lagrer} other {Oppdater informasjon} }",
      "success": "Bedriftsinformasjonen er oppdatert"
    },
    "admin_order": {
      "delivery_option": {
        "no": {
          "description": "Å hente bilen selv koster ingenting ekstra.",
          "title": "Nei, kun utlevering"
        },
        "subtitle": "Du kan velge om den ansatte skal få muligheten til å bestille levering til en valgfri adresse. Hvis ikke, må vedkommende hente bilen på valgt utleveringspunkt.",
        "title": "Vil du la den ansatte bestille hjemlevering?",
        "yes": {
          "description": "Pris for levering avhenger av avstanden fra utleveringspunktet.",
          "title": "Ja, den ansatte kan velge mellom utlevering eller levering"
        }
      },
      "delivery_time": {
        "subtitle": "Velg dato abonnementet skal starte og tid for første mulige hentetidspunkt. Man kan endre selve hentetidspunktet som er valgt inntil 3 virkedager før abonnementet starter.",
        "title": "Hvilken dato skal abonnementet starte?"
      },
      "employee": {
        "label": "Alle ansatte",
        "no_selection": "Velg ansatt",
        "subtitle": "Legg til den ansatte som skal benytte seg av bilen. Du kan når som helst bytte om på hvem som kan bruke bilen.",
        "text": "Ser du ikke den som skal bruke bilen? Legg til flere ansatte i",
        "title": "Hvem skal benytte seg av bilen?"
      },
      "location": {
        "address": "{address}",
        "label": "Adresse:",
        "subtitle": "Velg hvilken by bilen skal hentes i eller leveres ut fra.",
        "title": "I hvilken by skal bilen leveres ut?"
      },
      "mileage_option": {
        "choice": "{amount} km per mnd",
        "subtitle": "Alle fleksere har Kilometer Rollover. Kilometerne du ikke bruker denne måneden, får du med deg til neste. Dersom du bruker mer enn du har bestilt, betaler du en fast pris per overkjørte kilometer.",
        "title": "Hvilken kjørelengde?"
      },
      "summary": {
        "mileage_option": "{amount} km/mnd",
        "price": "{price, number, whole},-",
        "price_per_month": "{price, number, whole},-/mnd",
        "submit": "Opprett bestilling",
        "subtitle": "",
        "title": "Oppsummering",
        "total_price": "Fast månedspris"
      },
      "vehicle_option": {
        "1": {
          "description": "Betal for bilen måned til måned. Abonnementet fornyes automatisk hver måned frem til du sier opp.",
          "title": "Månedsabonnement"
        },
        "12": {
          "description": "Få vår laveste pris, og full fleksibilitet til å bytte bil hver eneste måned. Du kan også pause abonnementet inntil 30 dager, to ganger i året.",
          "title": "Årsabonnement"
        },
        "6": {
          "description": "Lavere pris, og full fleksibilitet til å bytte bil hver eneste måned. Du kan også pause abonnementet inntil 30 dager, to ganger i året.",
          "title": "Halvårsabonnement"
        },
        "button": "Hva er inkludert?",
        "choice": "Testing choice, change this",
        "subtitle": "Velg abonnementet som passer deg og ditt behov. Abonnementet starter å løpe den dagen du henter bilen, og fornyes den samme datoen hver måned.",
        "title": "Hvilket abonnement ønsker du?"
      }
    },
    "admin_order_confirmation": {
      "body": "Bekreftelse på oppretting av bestilling er sendt til din e-post. En e-post er også sendt til disponenten du la inn.",
      "links": {
        "dashboard": "Dashboard"
      },
      "subtitle": "",
      "summary": "Oppsummering",
      "title": "Takk for din bestilling!"
    },
    "bottom_sheet": {
      "close": "Lukk",
      "delivery": "Utlevering",
      "help": "Trenger du hjelp?",
      "included_in_subscription": "Inkludert i abonnementet",
      "info_bar": {
        "delivery": "Utlevering",
        "delivery_subtitle": "{isPickup, select, true {Hent selv} other {Kan velge hjemlevering} }",
        "location": "Lokasjon",
        "mileage_option": "Kjørelengde",
        "mileage_option_subtitle": "{km} km/mnd",
        "vehicle_type": "Bil"
      },
      "monthly_costs": "Faste kostnader",
      "monthly_price_label": "Månedspris",
      "one_time_costs": "Engangssum",
      "overview": "Se oversikt",
      "price": "{value, number, whole},-",
      "summary": "Oppsummering"
    },
    "cars": {
      "cancelled": "Biler du har levert tilbake",
      "card": {
        "active_car_status": "{status, select, active {Aktiv} ready {Kan hentes} pending {I bestilling} cancelling {Avbestilt} cancelled {Levert tilbake} other {Mangler status} }",
        "available_from": {
          "available": "{deliverableInRange} biler ledig {deliveryRangeDates}",
          "first_available": "{deliverable, select, true {Ledig fra {date, date, dayWithMonth}} other {Ikke tilgjengelig}}",
          "not_available": "Ikke tilgjengelig"
        },
        "ended_at": "Innlevert {date, date, twoDigit}",
        "price_per_month": "{price, number, groupedNoDecimal},-/mnd"
      },
      "contact_us": "Ta kontakt",
      "modal": {
        "cancel": {
          "cancel_button": "{cancelling, select, true {Tilbake} other {Avbryt}}",
          "completed": {
            "body_text": "En bekreftelse er sendt til deg og den som diponerer bilen. E-posten inneholder informasjon om innlevering av bilen.",
            "header": "Bilen er avbestilt"
          },
          "confirm_cancellation": "Bekreft avbestilling",
          "delivery_deadline": "Siste innleveringstid",
          "delivery_deadline_date": "{date, date, calendar}, kl {date, time, hhmm}",
          "delivery_services": "Noe du vil legge til?",
          "earliest_contracted_cancellation_date": "{date, date, calendar}",
          "earliest_contracted_cancellation_text": "Kan kanselleres fra",
          "header": "{cancelling, select, true {Bilen er avbestilt} other {Ønsker du å avbestille bilen?}}",
          "location": "Innleveringssted",
          "people_notified": "Hvem får beskjed om avbestillingen?",
          "service_amount": "+ {amount, number, plain},-"
        },
        "common": {
          "close": "Lukk",
          "monthly_price": {
            "label": "Månedspris",
            "tooltip": "Månedspris er summen av prisen for bilen og inkluderte kilometer.",
            "value": "{price, number, groupedNoDecimal},-"
          }
        },
        "details": {
          "cancel_car": "Avbestill bil",
          "cancel_title": "Trenger du ikke bilen lenger?",
          "car_cancelling_title": "Bilen er avbestilt",
          "earliest_cancellation_text": "Siden du har {subscription} kan denne bilen først avbestilles {date, date, calendar}",
          "edit_subscription": "Endre abonnement",
          "insurance": "Forsikring",
          "item_price": "{price, number, groupedNoDecimal},-",
          "item_price_included": "Inkludert",
          "mileage": "{distance} km/mnd",
          "next_schedule_info": "Prisene gjelder for neste periode",
          "return_info": "Innleveringsinformasjon",
          "roof_rack": "{type, select, roof_rack {Takstativ} roof_rack_skirack {Takstativ med skistativ} other {Takstativ} }",
          "title": "Detaljert informasjon"
        },
        "edit": {
          "cancel": "Avbryt",
          "mileage_option": {
            "changing": "Du har {before} km/mnd, men har bestilt {after} km/mnd fra neste periode.",
            "current": "Du har {amount} km/mnd.",
            "label": "{amount} km/mnd",
            "title": "Inkludert kjørelengde"
          },
          "save": "Lagre",
          "success": "Endringen ble lagret og vil gjelde fra neste periode.",
          "title": "Endre abonnement"
        },
        "employees": {
          "add_employee": "Legg til bruker",
          "cancel": "Avbryt",
          "employee_select_placeholder": "Legg til en ansatt fra listen",
          "empty_list": "Det er ingen disponenter av denne bilen",
          "header": "Hvem skal disponere bilen?",
          "list_header": "Ansatte som disponerer bilen i dag",
          "save": "Lagre"
        },
        "orderable": {
          "available_from": {
            "label": "Ledig fra",
            "tooltip": "Tilgjengelighet er basert på utleveringspunktet som er valgt som standard for bedriften."
          },
          "can_order": "{canOrder, select, true {Kan bestilles} other {Ikke tilgjengelig}}",
          "more_info": "Mer info",
          "order_car": "Start bestilling",
          "pickup_point": {
            "label": "Utleveringspunkt",
            "tooltip": "Utleveringspunkt kan endres under bestilling."
          }
        },
        "overview": {
          "add_employee": "Legg til disponent",
          "delivery_date": {
            "label": "Utleveringsdato",
            "tooltip": "Utleveringsdato kan endres av den ansatte.",
            "value": "{date, date, twoDigit}"
          },
          "km_driven": {
            "label": "Kjørte kilometer",
            "tooltip": "Antall tilgjengelige kilometer beregnes ut ifra den inkluderte månedlige kjørelengden på abonnementet. Dersom abonnementet startet i midten av en måned vil kjørelengden justeres basert på den gjenstående tiden i måneden. Ubrukte kilometer overføres til neste måned. Overkjørte kilometer regnes ut og faktureres på årsbasis eller ved innlevering av bilen.",
            "value": "{current} / {max} km"
          },
          "multiple_employees_on_car": "Flere ansatte disponerer denne bilen",
          "no_employees_on_car": "Det er ingen disponenter på bilen",
          "subscription_period": {
            "monthly": "Månedsabonnement",
            "monthly_lowercase": "månedsabonnement",
            "semi_annual": "Halvårsabonnement",
            "semi_annual_lowercase": "halvårsabonnement",
            "yearly": "Årsabonnement",
            "yearly_lowercase": "årsabonnement"
          },
          "who_disposes_car": "Hvem disponerer bilen?"
        }
      },
      "need_more_cars": "Trenger dere noen andre biler?",
      "no_cars_yet": "Du har ingen biler enda",
      "orderable": "Biler du kan bestille i",
      "your_cars": "Dine biler"
    },
    "change_password_modal": {
      "cancel": "Avbryt",
      "close": "Lukk",
      "form": {
        "new_password": {
          "label": "Nytt passord"
        },
        "new_password_confirm": {
          "label": "Bekreft nytt passord"
        },
        "old_password": {
          "label": "Gammelt passord"
        }
      },
      "submit": "Lagre",
      "success": "Passordet ble endret",
      "title": "Bytte passord"
    },
    "common": {
      "buttons": {
        "cancel": "Avbryt",
        "see_details": "Se detaljer"
      },
      "navigation": {
        "back": "Tilbake"
      },
      "price": {
        "groupedNoDecimal": "{price, number, groupedNoDecimal},-",
        "whole": "{price, number, whole},-"
      }
    },
    "company_info": {
      "change_password": "Bytte passord",
      "change_password_body": "Du kan når som helst bytte passord. Endringen gjelder kun Admin-kontoen.",
      "change_password_button": "Endre passord",
      "confirm_unsaved_transition": "Du har ulagrede endringer. Ønsker du likevel å navigere bort fra denne siden?",
      "form": {
        "address": {
          "label": "Adresse",
          "placeholder": "Gateadresse 21",
          "placeholder_2": "Adresselinje 2"
        },
        "city": {
          "label": "Sted",
          "placeholder": "Tettsted"
        },
        "company_name": {
          "label": "Bedriftsnavn",
          "placeholder": "Bedriften AS"
        },
        "contact_person": {
          "label": "Kontaktperson",
          "placeholder": "Navn Navnesen"
        },
        "email": {
          "label": "E-post",
          "placeholder": "post@epost.no"
        },
        "phone": {
          "label": "Telefon",
          "placeholder": "98765432"
        },
        "pickup_point": {
          "label": "Foretrukket utleveringspunkt"
        },
        "save": "Lagre",
        "success": "Endringene ble lagret",
        "zip_code": {
          "label": "Postnummer",
          "placeholder": "3434"
        }
      },
      "title": "Om bedriften"
    },
    "confirm_new_password": {
      "body": "Velg nytt passord under. Eneste begrensning er 8 tegn, bare husk at lengre passord som oftest er sikrere.",
      "submit": "Lagre nytt passord",
      "submitting": "Lagrer",
      "success": "Nytt passord lagret, sender deg videre til innlogging",
      "title": "Velg nytt passord"
    },
    "consent_manager": {
      "banner": {
        "body": "Vi bruker cookies for å personalisere innhold og annonser, kunne bruke tjenester for sosiale medier og for å analysere trafikken til nettsidene våre",
        "buttons": {
          "accept_all": "Godta alle",
          "accept_legitimate": "Kun nødvendige",
          "settings": "Endre innstillinger"
        },
        "title": "Fleks bruker cookies"
      },
      "preferences": {
        "buttons": {
          "cancel": "Avbryt",
          "save": "Lagre"
        },
        "table": {
          "allow": "Tillat",
          "category": "Kategori",
          "consent": "Ja",
          "n_a": "N/A",
          "purpose": "Hvorfor",
          "tools": "Verktøy"
        },
        "title": "Cookie-innstillinger"
      }
    },
    "consents": {
      "marketing": "{marketing, select, true {Jeg ønsker å motta nyhetsbrev} other {Jeg ønsker ikke å motta nyhetsbrev} }",
      "terms": "{terms, select, true {Jeg aksepterer <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} other {Jeg aksepterer ikke <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} }"
    },
    "dashboard": {
      "last_invoice": {
        "amount": "{amount, number, NOK}",
        "invoices": "Se alle fakturaer",
        "title": "Siste faktura",
        "total_label": "Totalbeløp"
      },
      "num_cars": {
        "description": "Antall biler på veien. Gå til Bilpark for å administrere biler.",
        "title": "Antall biler på veien"
      },
      "num_employees": {
        "description": "Antall ansatte som kan benytte seg av biler i bilparken.",
        "title": "Antall ansatte"
      },
      "welcome": "Velkommen!"
    },
    "delivery": {
      "delivery_date": "{isPickup, select, true {Velg utleveringsdato} other {Velg leveringsdato} }",
      "delivery_time": "Velg tidspunkt",
      "error": "Noe gikk galt. Vennligst kontakt kundeservice",
      "submit": "Betaling"
    },
    "delivery_info": {
      "address": "Adresse",
      "allow_home_delivery": {
        "label": "Kan velge hjemlevering",
        "value": "{allowHomeDelivery, select, true {Ja} other {Nei} }"
      },
      "date": {
        "label": "Tid og dato",
        "value": "{date, date, calendarWithTime}"
      },
      "time": {
        "label": "Tidspunkt",
        "value": "kl. {fromTime, time, hhmm} - {toTime, time, hhmm}"
      },
      "type": {
        "label": "Type",
        "value": "{type, select, pickup {Hent selv} home {Hjemlevering} other {}}"
      },
      "your_address": "(din adresse)",
      "zip_city": {
        "label": "Postnummer, sted",
        "value": "{zip}, {city}"
      }
    },
    "edit_consents": {
      "marketing": "{marketing, select, true {Jeg ønsker å motta nyhetsbrev} other {Jeg ønsker ikke å motta nyhetsbrev} }",
      "submit": "{submitting, select, true {Lagrer} other {Oppdater} }",
      "terms": "{terms, select, true {Jeg aksepterer <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} other {Jeg aksepterer ikke <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} }"
    },
    "edit_personalia": {
      "submit": "{submitting, select, true {Lagrer} other {Oppdater informasjon} }",
      "success": "Bruker oppdatert"
    },
    "employees": {
      "add_employee": "Legg til ansatt",
      "add_modal": {
        "body": "Når du legger til ansatte får de automatisk en invitasjon til Fleks Bedrift. Den ansatte må akseptere invitasjonen og opprette en bruker før du kan tildele vedkommende en bil.",
        "cancel": "Avbryt",
        "email": "E-post",
        "email_placeholder": "navn@post.no",
        "errors": {
          "exists": "Brukeren finnes allerede."
        },
        "save": "Lagre",
        "title": "Legg til ansatt"
      },
      "car": "Bil",
      "employee_role": "{role, select, admin {Administrator} employee {Ansatt} other {-} }",
      "employee_status": "{active, select, true {Aktiv} other {Invitasjon sendt} }",
      "has_multiple_cars": "Brukeren disponerer flere biler",
      "name": "Navn",
      "no_employees": "Du har ikke lagt til noen ansatte enda",
      "remove_modal": {
        "body": "Er du sikker på at du vil slette brukeren? Hvis brukeren har tilgang til en bil, vil den miste denne.",
        "cancel": "Avbryt",
        "save": "Fjern bruker",
        "title": "Fjern bruker"
      },
      "role": "Type bruker",
      "selected_employee": "Valgt ansatt",
      "status": "Status",
      "title": "Ansatte",
      "username": "Brukernavn"
    },
    "errors": {
      "change_password": {
        "bad_password": "Kunne ikke endre passord, er du sikker på at det gamle passordet er skrevet riktig?",
        "default_error": "Oi! Det har skjedd en feil. Om feilen vedvarer kontakt Fleks teamet"
      },
      "company_admin": {
        "delete": {
          "self": "Administrator kan ikke slette seg selv."
        }
      },
      "employee_order": {
        "expired": "Denne ordren er allerede utgått. Kontakt administrator i din bedrift.",
        "no_such_basket": "Ingen ordre funnet for din bruker. Brukte du samme epostadresse som invitasjonen ble mottatt på da du registrerte deg eller logget inn?"
      },
      "generic": "Noe galt skjedde.",
      "invite": {
        "exists": "Denne brukeren er allerede invitert."
      },
      "login": {
        "user_not_found": "Beklager, du har tastet inn feil e-post eller passord",
        "wrong_password": "Beklager, du har tastet inn feil e-post eller passord",
        "wrong_username_or_password": "Beklager, du har tastet inn feil brukernavn eller passord"
      },
      "user": {
        "email_taken": "E-posten er allerede knyttet til en bedriftsbruker.",
        "invalid_market": "E-posten allerede er knyttet til en privat bruker av Fleks."
      },
      "user_not_found": "Ugyldig bruker",
      "user_vehicle": {
        "delivery_active": "Brukeren du prøver å slette er tilknyttet en utlevering",
        "last_active": "Alle biler må ha minst én disponent, legg til en annen før du fjerner brukeren."
      }
    },
    "fields": {
      "address": "Adresse",
      "address_line1": "Adresselinje 1",
      "address_line2": "Adresselinje 2",
      "city": "By",
      "country": "Land",
      "email": "E-post",
      "first_name": "Fornavn",
      "full_name": "Navn",
      "last_name": "Etternavn",
      "password": "Passord",
      "password_confirmation": "Bekreft passord",
      "phone_number": "Telefonnummer",
      "ssn": "Personnummer",
      "username": "E-post",
      "zip_code": "Postnummer"
    },
    "home": {
      "business_user": {
        "subtitle": "Bruk appen for å administrere bilen du har tilgang til som ansatt. Hvis du har fått beskjed om å aktivere en bil, følg lenken i e-posten.",
        "title": "Du er ikke administrator for en bedrift"
      },
      "private_user": {
        "link_text": "Til min side",
        "subtitle": "Det var kanskje ikke hit du skulle? For å administrere ditt private abonnement må du logge inn på min side, følg lenken under",
        "title": "Du har ikke en bedriftskonto"
      }
    },
    "home_delivery_costs": {
      "explanation": "*Den ansatte får mulighet til å velge hjemlevering når hen aktiverer bilen. Dersom den ansatte ønsker hjemlevering vil kostnaden komme på første faktura.",
      "name": "Mulighet for hjemlevering*",
      "price": "{price, number, whole},-"
    },
    "how_it_works": {
      "activate_cars": {
        "body": "Finn bilene du kan velge mellom i '<span class=\"font-bold\">'Bilparken'</span>'. Velg den bilen du ønsker å bestille, gå igjennom bestillingsløpet, legg til en ansatt som skal disponere bilen og velg når abonnementet skal starte.",
        "heading": "Bestill biler"
      },
      "add_employees": {
        "body": "Før du bestiller biler må du legge til de ansatte som skal disponere dem. Gå til '<span class=\"font-bold\">'Ansatte'</span>' i menyen på venstre side og trykk '<span class=\"font-bold\">'Legg til ansatt'</span>'.",
        "heading": "Legg til ansatte"
      },
      "contact_us": "Ta kontakt",
      "invoices": {
        "body": "Få oversikt over alle betalte og ikke betalte fakturaer ved å gå til '<span class=\"font-bold\">'Fakturaer'</span>.",
        "heading": "Fakturaer",
        "inline_link_text": "Fakturaer."
      },
      "questions": "Noe du lurer på?",
      "title": "Slik fungerer Bedriftsportalen"
    },
    "invoice_status": {
      "due": "Ikke forfalt",
      "paid": "Betalt",
      "pastDue": "Forfalt"
    },
    "invoices": {
      "list": {
        "date": "{date, date, twoDigit}",
        "description": "Fleksbil og andre tjenester",
        "total": {
          "label": "Totalbeløp",
          "value": "{value, number, NOK}"
        }
      },
      "modal": {
        "amount": "Antall",
        "close": "Lukk",
        "date": {
          "label": "Dato",
          "singleValue": "{date, date, twoDigit}",
          "value": "{from, date, twoDigit}-{to, date, twoDigit}"
        },
        "description": "Beskrivelse",
        "price": {
          "label": "Beløp",
          "value": "{value, number, NOK}"
        },
        "reg_num": "Regnr.",
        "tax": {
          "label": "MVA",
          "value": "{value} %"
        },
        "total": {
          "label": "Totalbeløp",
          "value": "{value, number, NOK}"
        }
      },
      "no_invoices": "Ingen fakturaer",
      "paid": "Betalte",
      "title": "Fakturaer",
      "unpaid": "Ubetalte"
    },
    "login": {
      "fields": {
        "password": "Passord",
        "password_confirmation": "Gjenta passord",
        "username": "E-post"
      },
      "no_user": "Ingen bruker?",
      "placeholder": {
        "login": "navn@epost.no",
        "password": "sterkt passord"
      },
      "reset_password": "Glemt passord?",
      "submit": "{submitting, select, true {Logger inn} other {Logg inn} }",
      "title": "Logg inn"
    },
    "logout": "Logg ut",
    "modify_order": {
      "delivery_option": {
        "home": {
          "address": "Hvilken adresse vil du ha bilen levert til?",
          "subtitle": "Vi bringer bilen til adressen du velger til avtalt tidspunkt.",
          "title": "Hjemlevering"
        },
        "pickup": {
          "address": "{address}",
          "pickup_title": "Du henter bilen her",
          "subtitle": "Dra til Fleks sitt utleveringspunkt for å hente bilen.",
          "title": "Hente selv"
        },
        "subtitle": "Du kan velge selv om du ønsker å dra til utleveringspunktet for å hente bilen, eller få bilen levert til en valgfri adresse.",
        "title": "Hvordan ønsker du å motta bilen?"
      },
      "delivery_time": {
        "submit": "Send bekreftelse",
        "subtitle": "Du kan velge å hente bilen den dagen abonemmentet starter, eller opp til fem virkedager etter.",
        "time_title": "Tidspunkt",
        "title": "Når ønsker du å hente bilen?"
      }
    },
    "modify_order_confirmation": {
      "body": "I mellomtiden kan du laste ned fleksappen. Appen vil fungere som din digitale nøkkel, i tillegg foregår inn- og utleveringsprosessen her. Vi anbefaler at du laster ned appen og logger deg inn i god tid før du mottar bilen.",
      "frontpage": "Til forsiden",
      "title": "Du kan snart hente bilen!"
    },
    "modify_order_entrypoint": {
      "body": "Administrator for bedriften har bestilt en bil til deg og valgt følgende leveringstid. Du kan endre denne ved å følge linken under.",
      "delivery_info": "Utleveringsinfo",
      "links": {
        "abort": "Avbryt",
        "change": "Endre"
      },
      "not_found": {
        "subtitle": "Prøv igjen eller kontakt kundeservice.",
        "title": "Fant ikke bestillingen"
      },
      "past_due": {
        "subtitle": "Det er for kort tid til utlevering av bilen til at du kan endre dato eller tidspunkt.",
        "title": "Denne bestillingen kan ikke endres"
      },
      "submit": "Bekreft",
      "title": "En bil har blitt bestilt til deg!"
    },
    "monthly_costs_table": {
      "included": "inkludert",
      "price": "{price, number, whole},-/mnd",
      "total": "Totalt"
    },
    "nav": {
      "back_to_fleks": "Tilbake til fleks.no",
      "business": "Bedrift",
      "cars": "Bilpark",
      "company_info": "Om bedriften",
      "dashboard": "Dashboard",
      "employees": "Ansatte",
      "invoices": "Fakturaer",
      "login": "Logg inn",
      "sign_out": "Logg ut"
    },
    "no_user": {
      "cancel": "Avbryt",
      "contact": "Ta kontakt",
      "subtitle": "Ta kontakt med oss så setter vi oppe en plan for din bedrift",
      "title": "Ønsker du å bli bedriftskunde?"
    },
    "not_found": {
      "message": "Sjekk linken eller prøv igjen senere.",
      "subtitle": "Siden du prøvde å besøke finnes ikke.",
      "title": "404"
    },
    "order": {
      "abort": {
        "body": "Er du sikker på at du vil avbryte bestillingen?",
        "body_change": "Er du sikker på at du vil avbryte endringen?",
        "cancel": "Nei, fortsett bestillingen",
        "confirm": "Ja",
        "title": "Avbryt"
      },
      "common": {
        "back": "Tilbake",
        "exit": "Avbryt",
        "next": "Gå videre"
      }
    },
    "order_summary": {
      "costs": {
        "price_per_month": "{price, number, whole},-/mnd",
        "title": "Faste kostnader"
      },
      "delivery": {
        "title": "Utlevering"
      },
      "one_time": {
        "price": "{price, number, whole},-",
        "title": "Engangssum"
      }
    },
    "page_titles": {
      "accept_invite": "Opprett Bruker",
      "admin": "Administrator",
      "application": "Fleks Bedrift",
      "confirm_new_password": "Velg nytt passord",
      "login": "Logg inn",
      "no_user": "Bli bedriftskunde",
      "reset_password": "Glemt passord"
    },
    "price": {
      "included": "inkludert",
      "per_employee": "{amount, number, plain},- per ansatt",
      "per_month": "{amount, number, plain},-/mnd"
    },
    "reset_password": {
      "back_to_login": "Tilbake til innlogging",
      "body": "Har du glemt passordet ditt? Det gjør ingenting! Skriv inn e-posten din under, så sender vi deg en e-post med informasjon om hvordan du kan sette nytt passord.",
      "form": {
        "email": {
          "label": "E-post",
          "placeholder": "E-post"
        },
        "submit_button": "Send meg instruksjoner"
      },
      "success_text": "Vi har sendt deg en e-post med instruksjoner.",
      "title": "Glemt passord"
    },
    "selection": {
      "from_price_per_month": "Fra {price, number, whole},-/mnd",
      "included": "inkludert",
      "price": "{price, number, whole},-",
      "price_per_month": "{price, number, whole},-/mnd",
      "select": "{selected, select, true {Valgt} other {Velg} }"
    },
    "signup": {
      "errors": {
        "default_error": "Oi! Det har skjedd en feil. Sjekk at brukernavn er riktig. Om feilen vedvarer kontakt Fleks teamet",
        "not_accepted_terms": "Du må akseptere betingelsene før du kan registrere deg"
      },
      "first_name": "Fornavn",
      "last_name": "Etternavn",
      "login_button": "Logg inn",
      "login_info": "Hvis du allerede har en bruker?",
      "placeholder": {
        "email": "navn@epost.no",
        "password": "sterkt passord",
        "password_confirmation": "gjenta sterkt passord"
      },
      "submit": "{submitting, select, true {Oppretter} other {Opprett profil} }",
      "subtitle": "Registrer deg for å bestille bil &#x1F697 &#x1F4A8",
      "title": "Ny til Fleks?"
    }
  }], ["nb-no", {
    "accept_invite": {
      "accepted": {
        "body1": "Du har akseptert invitasjonen og din bruker har blitt opprettet!",
        "body2": "Administrator for bedriften kan nå bestille en bil til deg eller gi deg tilgang til en eksisterende bil. Bruk appen for å administrere bilen du har tilgang til som ansatt.",
        "title": "Bruker Opprettet"
      },
      "not_found": {
        "subtitle": "Prøv igjen eller kontakt kundeservice.",
        "title": "Fant ikke Invitasjonen"
      },
      "submit": "Bekreft",
      "title": "Opprett Bruker"
    },
    "account_info": {
      "button": "Endre",
      "edit_header": "Endre",
      "fields": {
        "address": "Adresse",
        "address_line1": "Adresselinje 1",
        "address_line2": "Adresselinje 2",
        "city": "By",
        "email": "E-post",
        "name": "Navn",
        "phone_number": "Telefonnummer",
        "zip_code": "Postnummer"
      },
      "header": "Om bedriften",
      "submit": "{submitting, select, true {Lagrer} other {Oppdater informasjon} }",
      "success": "Bedriftsinformasjonen er oppdatert"
    },
    "admin_order": {
      "delivery_option": {
        "no": {
          "description": "Å hente bilen selv koster ingenting ekstra.",
          "title": "Nei, kun utlevering"
        },
        "subtitle": "Du kan velge om den ansatte skal få muligheten til å bestille levering til en valgfri adresse. Hvis ikke, må vedkommende hente bilen på valgt utleveringspunkt.",
        "title": "Vil du la den ansatte bestille hjemlevering?",
        "yes": {
          "description": "Pris for levering avhenger av avstanden fra utleveringspunktet.",
          "title": "Ja, den ansatte kan velge mellom utlevering eller levering"
        }
      },
      "delivery_time": {
        "subtitle": "Velg dato abonnementet skal starte og tid for første mulige hentetidspunkt. Man kan endre selve hentetidspunktet som er valgt inntil 3 virkedager før abonnementet starter.",
        "title": "Hvilken dato skal abonnementet starte?"
      },
      "employee": {
        "label": "Alle ansatte",
        "no_selection": "Velg ansatt",
        "subtitle": "Legg til den ansatte som skal benytte seg av bilen. Du kan når som helst bytte om på hvem som kan bruke bilen.",
        "text": "Ser du ikke den som skal bruke bilen? Legg til flere ansatte i",
        "title": "Hvem skal benytte seg av bilen?"
      },
      "location": {
        "address": "{address}",
        "label": "Adresse:",
        "subtitle": "Velg hvilken by bilen skal hentes i eller leveres ut fra.",
        "title": "I hvilken by skal bilen leveres ut?"
      },
      "mileage_option": {
        "choice": "{amount} km per mnd",
        "subtitle": "Alle fleksere har Kilometer Rollover. Kilometerne du ikke bruker denne måneden, får du med deg til neste. Dersom du bruker mer enn du har bestilt, betaler du en fast pris per overkjørte kilometer.",
        "title": "Hvilken kjørelengde?"
      },
      "summary": {
        "mileage_option": "{amount} km/mnd",
        "price": "{price, number, whole},-",
        "price_per_month": "{price, number, whole},-/mnd",
        "submit": "Opprett bestilling",
        "subtitle": "",
        "title": "Oppsummering",
        "total_price": "Fast månedspris"
      },
      "vehicle_option": {
        "1": {
          "description": "Betal for bilen måned til måned. Abonnementet fornyes automatisk hver måned frem til du sier opp.",
          "title": "Månedsabonnement"
        },
        "12": {
          "description": "Få vår laveste pris, og full fleksibilitet til å bytte bil hver eneste måned. Du kan også pause abonnementet inntil 30 dager, to ganger i året.",
          "title": "Årsabonnement"
        },
        "6": {
          "description": "Lavere pris, og full fleksibilitet til å bytte bil hver eneste måned. Du kan også pause abonnementet inntil 30 dager, to ganger i året.",
          "title": "Halvårsabonnement"
        },
        "button": "Hva er inkludert?",
        "choice": "Testing choice, change this",
        "subtitle": "Velg abonnementet som passer deg og ditt behov. Abonnementet starter å løpe den dagen du henter bilen, og fornyes den samme datoen hver måned.",
        "title": "Hvilket abonnement ønsker du?"
      }
    },
    "admin_order_confirmation": {
      "body": "Bekreftelse på oppretting av bestilling er sendt til din e-post. En e-post er også sendt til disponenten du la inn.",
      "links": {
        "dashboard": "Dashboard"
      },
      "subtitle": "",
      "summary": "Oppsummering",
      "title": "Takk for din bestilling!"
    },
    "bottom_sheet": {
      "close": "Lukk",
      "delivery": "Utlevering",
      "help": "Trenger du hjelp?",
      "included_in_subscription": "Inkludert i abonnementet",
      "info_bar": {
        "delivery": "Utlevering",
        "delivery_subtitle": "{isPickup, select, true {Hent selv} other {Kan velge hjemlevering} }",
        "location": "Lokasjon",
        "mileage_option": "Kjørelengde",
        "mileage_option_subtitle": "{km} km/mnd",
        "vehicle_type": "Bil"
      },
      "monthly_costs": "Faste kostnader",
      "monthly_price_label": "Månedspris",
      "one_time_costs": "Engangssum",
      "overview": "Se oversikt",
      "price": "{value, number, whole},-",
      "summary": "Oppsummering"
    },
    "cars": {
      "cancelled": "Biler du har levert tilbake",
      "card": {
        "active_car_status": "{status, select, active {Aktiv} ready {Kan hentes} pending {I bestilling} cancelling {Avbestilt} cancelled {Levert tilbake} other {Mangler status} }",
        "available_from": {
          "available": "{deliverableInRange} biler ledig {deliveryRangeDates}",
          "first_available": "{deliverable, select, true {Ledig fra {date, date, dayWithMonth}} other {Ikke tilgjengelig}}",
          "not_available": "Ikke tilgjengelig"
        },
        "ended_at": "Innlevert {date, date, twoDigit}",
        "price_per_month": "{price, number, groupedNoDecimal},-/mnd"
      },
      "contact_us": "Ta kontakt",
      "modal": {
        "cancel": {
          "cancel_button": "{cancelling, select, true {Tilbake} other {Avbryt}}",
          "completed": {
            "body_text": "En bekreftelse er sendt til deg og den som diponerer bilen. E-posten inneholder informasjon om innlevering av bilen.",
            "header": "Bilen er avbestilt"
          },
          "confirm_cancellation": "Bekreft avbestilling",
          "delivery_deadline": "Siste innleveringstid",
          "delivery_deadline_date": "{date, date, calendar}, kl {date, time, hhmm}",
          "delivery_services": "Noe du vil legge til?",
          "earliest_contracted_cancellation_date": "{date, date, calendar}",
          "earliest_contracted_cancellation_text": "Kan kanselleres fra",
          "header": "{cancelling, select, true {Bilen er avbestilt} other {Ønsker du å avbestille bilen?}}",
          "location": "Innleveringssted",
          "people_notified": "Hvem får beskjed om avbestillingen?",
          "service_amount": "+ {amount, number, plain},-"
        },
        "common": {
          "close": "Lukk",
          "monthly_price": {
            "label": "Månedspris",
            "tooltip": "Månedspris er summen av prisen for bilen og inkluderte kilometer.",
            "value": "{price, number, groupedNoDecimal},-"
          }
        },
        "details": {
          "cancel_car": "Avbestill bil",
          "cancel_title": "Trenger du ikke bilen lenger?",
          "car_cancelling_title": "Bilen er avbestilt",
          "earliest_cancellation_text": "Siden du har {subscription} kan denne bilen først avbestilles {date, date, calendar}",
          "edit_subscription": "Endre abonnement",
          "insurance": "Forsikring",
          "item_price": "{price, number, groupedNoDecimal},-",
          "item_price_included": "Inkludert",
          "mileage": "{distance} km/mnd",
          "next_schedule_info": "Prisene gjelder for neste periode",
          "return_info": "Innleveringsinformasjon",
          "roof_rack": "{type, select, roof_rack {Takstativ} roof_rack_skirack {Takstativ med skistativ} other {Takstativ} }",
          "title": "Detaljert informasjon"
        },
        "edit": {
          "cancel": "Avbryt",
          "mileage_option": {
            "changing": "Du har {before} km/mnd, men har bestilt {after} km/mnd fra neste periode.",
            "current": "Du har {amount} km/mnd.",
            "label": "{amount} km/mnd",
            "title": "Inkludert kjørelengde"
          },
          "save": "Lagre",
          "success": "Endringen ble lagret og vil gjelde fra neste periode.",
          "title": "Endre abonnement"
        },
        "employees": {
          "add_employee": "Legg til bruker",
          "cancel": "Avbryt",
          "employee_select_placeholder": "Legg til en ansatt fra listen",
          "empty_list": "Det er ingen disponenter av denne bilen",
          "header": "Hvem skal disponere bilen?",
          "list_header": "Ansatte som disponerer bilen i dag",
          "save": "Lagre"
        },
        "orderable": {
          "available_from": {
            "label": "Ledig fra",
            "tooltip": "Tilgjengelighet er basert på utleveringspunktet som er valgt som standard for bedriften."
          },
          "can_order": "{canOrder, select, true {Kan bestilles} other {Ikke tilgjengelig}}",
          "more_info": "Mer info",
          "order_car": "Start bestilling",
          "pickup_point": {
            "label": "Utleveringspunkt",
            "tooltip": "Utleveringspunkt kan endres under bestilling."
          }
        },
        "overview": {
          "add_employee": "Legg til disponent",
          "delivery_date": {
            "label": "Utleveringsdato",
            "tooltip": "Utleveringsdato kan endres av den ansatte.",
            "value": "{date, date, twoDigit}"
          },
          "km_driven": {
            "label": "Kjørte kilometer",
            "tooltip": "Antall tilgjengelige kilometer beregnes ut ifra den inkluderte månedlige kjørelengden på abonnementet. Dersom abonnementet startet i midten av en måned vil kjørelengden justeres basert på den gjenstående tiden i måneden. Ubrukte kilometer overføres til neste måned. Overkjørte kilometer regnes ut og faktureres på årsbasis eller ved innlevering av bilen.",
            "value": "{current} / {max} km"
          },
          "multiple_employees_on_car": "Flere ansatte disponerer denne bilen",
          "no_employees_on_car": "Det er ingen disponenter på bilen",
          "subscription_period": {
            "monthly": "Månedsabonnement",
            "monthly_lowercase": "månedsabonnement",
            "semi_annual": "Halvårsabonnement",
            "semi_annual_lowercase": "halvårsabonnement",
            "yearly": "Årsabonnement",
            "yearly_lowercase": "årsabonnement"
          },
          "who_disposes_car": "Hvem disponerer bilen?"
        }
      },
      "need_more_cars": "Trenger dere noen andre biler?",
      "no_cars_yet": "Du har ingen biler enda",
      "orderable": "Biler du kan bestille i",
      "your_cars": "Dine biler"
    },
    "change_password_modal": {
      "cancel": "Avbryt",
      "close": "Lukk",
      "form": {
        "new_password": {
          "label": "Nytt passord"
        },
        "new_password_confirm": {
          "label": "Bekreft nytt passord"
        },
        "old_password": {
          "label": "Gammelt passord"
        }
      },
      "submit": "Lagre",
      "success": "Passordet ble endret",
      "title": "Bytte passord"
    },
    "common": {
      "buttons": {
        "cancel": "Avbryt",
        "see_details": "Se detaljer"
      },
      "navigation": {
        "back": "Tilbake"
      },
      "price": {
        "groupedNoDecimal": "{price, number, groupedNoDecimal},-",
        "whole": "{price, number, whole},-"
      }
    },
    "company_info": {
      "change_password": "Bytte passord",
      "change_password_body": "Du kan når som helst bytte passord. Endringen gjelder kun Admin-kontoen.",
      "change_password_button": "Endre passord",
      "confirm_unsaved_transition": "Du har ulagrede endringer. Ønsker du likevel å navigere bort fra denne siden?",
      "form": {
        "address": {
          "label": "Adresse",
          "placeholder": "Gateadresse 21",
          "placeholder_2": "Adresselinje 2"
        },
        "city": {
          "label": "Sted",
          "placeholder": "Tettsted"
        },
        "company_name": {
          "label": "Bedriftsnavn",
          "placeholder": "Bedriften AS"
        },
        "contact_person": {
          "label": "Kontaktperson",
          "placeholder": "Navn Navnesen"
        },
        "email": {
          "label": "E-post",
          "placeholder": "post@epost.no"
        },
        "phone": {
          "label": "Telefon",
          "placeholder": "98765432"
        },
        "pickup_point": {
          "label": "Foretrukket utleveringspunkt"
        },
        "save": "Lagre",
        "success": "Endringene ble lagret",
        "zip_code": {
          "label": "Postnummer",
          "placeholder": "3434"
        }
      },
      "title": "Om bedriften"
    },
    "confirm_new_password": {
      "body": "Velg nytt passord under. Eneste begrensning er 8 tegn, bare husk at lengre passord som oftest er sikrere.",
      "submit": "Lagre nytt passord",
      "submitting": "Lagrer",
      "success": "Nytt passord lagret, sender deg videre til innlogging",
      "title": "Velg nytt passord"
    },
    "consent_manager": {
      "banner": {
        "body": "Vi bruker cookies for å personalisere innhold og annonser, kunne bruke tjenester for sosiale medier og for å analysere trafikken til nettsidene våre",
        "buttons": {
          "accept_all": "Godta alle",
          "accept_legitimate": "Kun nødvendige",
          "settings": "Endre innstillinger"
        },
        "title": "Fleks bruker cookies"
      },
      "preferences": {
        "buttons": {
          "cancel": "Avbryt",
          "save": "Lagre"
        },
        "table": {
          "allow": "Tillat",
          "category": "Kategori",
          "consent": "Ja",
          "n_a": "N/A",
          "purpose": "Hvorfor",
          "tools": "Verktøy"
        },
        "title": "Cookie-innstillinger"
      }
    },
    "consents": {
      "marketing": "{marketing, select, true {Jeg ønsker å motta nyhetsbrev} other {Jeg ønsker ikke å motta nyhetsbrev} }",
      "terms": "{terms, select, true {Jeg aksepterer <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} other {Jeg aksepterer ikke <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} }"
    },
    "dashboard": {
      "last_invoice": {
        "amount": "{amount, number, NOK}",
        "invoices": "Se alle fakturaer",
        "title": "Siste faktura",
        "total_label": "Totalbeløp"
      },
      "num_cars": {
        "description": "Antall biler på veien. Gå til Bilpark for å administrere biler.",
        "title": "Antall biler på veien"
      },
      "num_employees": {
        "description": "Antall ansatte som kan benytte seg av biler i bilparken.",
        "title": "Antall ansatte"
      },
      "welcome": "Velkommen!"
    },
    "delivery": {
      "delivery_date": "{isPickup, select, true {Velg utleveringsdato} other {Velg leveringsdato} }",
      "delivery_time": "Velg tidspunkt",
      "error": "Noe gikk galt. Vennligst kontakt kundeservice",
      "submit": "Betaling"
    },
    "delivery_info": {
      "address": "Adresse",
      "allow_home_delivery": {
        "label": "Kan velge hjemlevering",
        "value": "{allowHomeDelivery, select, true {Ja} other {Nei} }"
      },
      "date": {
        "label": "Tid og dato",
        "value": "{date, date, calendarWithTime}"
      },
      "time": {
        "label": "Tidspunkt",
        "value": "kl. {fromTime, time, hhmm} - {toTime, time, hhmm}"
      },
      "type": {
        "label": "Type",
        "value": "{type, select, pickup {Hent selv} home {Hjemlevering} other {}}"
      },
      "your_address": "(din adresse)",
      "zip_city": {
        "label": "Postnummer, sted",
        "value": "{zip}, {city}"
      }
    },
    "edit_consents": {
      "marketing": "{marketing, select, true {Jeg ønsker å motta nyhetsbrev} other {Jeg ønsker ikke å motta nyhetsbrev} }",
      "submit": "{submitting, select, true {Lagrer} other {Oppdater} }",
      "terms": "{terms, select, true {Jeg aksepterer <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} other {Jeg aksepterer ikke <a target=\"_blank\" href=\"https://fleks.no/avtalevilkar\"><u>vilkårene</u></a>} }"
    },
    "edit_personalia": {
      "submit": "{submitting, select, true {Lagrer} other {Oppdater informasjon} }",
      "success": "Bruker oppdatert"
    },
    "employees": {
      "add_employee": "Legg til ansatt",
      "add_modal": {
        "body": "Når du legger til ansatte får de automatisk en invitasjon til Fleks Bedrift. Den ansatte må akseptere invitasjonen og opprette en bruker før du kan tildele vedkommende en bil.",
        "cancel": "Avbryt",
        "email": "E-post",
        "email_placeholder": "navn@post.no",
        "errors": {
          "exists": "Brukeren finnes allerede."
        },
        "save": "Lagre",
        "title": "Legg til ansatt"
      },
      "car": "Bil",
      "employee_role": "{role, select, admin {Administrator} employee {Ansatt} other {-} }",
      "employee_status": "{active, select, true {Aktiv} other {Invitasjon sendt} }",
      "has_multiple_cars": "Brukeren disponerer flere biler",
      "name": "Navn",
      "no_employees": "Du har ikke lagt til noen ansatte enda",
      "remove_modal": {
        "body": "Er du sikker på at du vil slette brukeren? Hvis brukeren har tilgang til en bil, vil den miste denne.",
        "cancel": "Avbryt",
        "save": "Fjern bruker",
        "title": "Fjern bruker"
      },
      "role": "Type bruker",
      "selected_employee": "Valgt ansatt",
      "status": "Status",
      "title": "Ansatte",
      "username": "Brukernavn"
    },
    "errors": {
      "change_password": {
        "bad_password": "Kunne ikke endre passord, er du sikker på at det gamle passordet er skrevet riktig?",
        "default_error": "Oi! Det har skjedd en feil. Om feilen vedvarer kontakt Fleks teamet"
      },
      "company_admin": {
        "delete": {
          "self": "Administrator kan ikke slette seg selv."
        }
      },
      "employee_order": {
        "expired": "Denne ordren er allerede utgått. Kontakt administrator i din bedrift.",
        "no_such_basket": "Ingen ordre funnet for din bruker. Brukte du samme epostadresse som invitasjonen ble mottatt på da du registrerte deg eller logget inn?"
      },
      "generic": "Noe galt skjedde.",
      "invite": {
        "exists": "Denne brukeren er allerede invitert."
      },
      "login": {
        "user_not_found": "Beklager, du har tastet inn feil e-post eller passord",
        "wrong_password": "Beklager, du har tastet inn feil e-post eller passord",
        "wrong_username_or_password": "Beklager, du har tastet inn feil brukernavn eller passord"
      },
      "user": {
        "email_taken": "E-posten er allerede knyttet til en bedriftsbruker.",
        "invalid_market": "E-posten allerede er knyttet til en privat bruker av Fleks."
      },
      "user_not_found": "Ugyldig bruker",
      "user_vehicle": {
        "delivery_active": "Brukeren du prøver å slette er tilknyttet en utlevering",
        "last_active": "Alle biler må ha minst én disponent, legg til en annen før du fjerner brukeren."
      }
    },
    "fields": {
      "address": "Adresse",
      "address_line1": "Adresselinje 1",
      "address_line2": "Adresselinje 2",
      "city": "By",
      "country": "Land",
      "email": "E-post",
      "first_name": "Fornavn",
      "full_name": "Navn",
      "last_name": "Etternavn",
      "password": "Passord",
      "password_confirmation": "Bekreft passord",
      "phone_number": "Telefonnummer",
      "ssn": "Personnummer",
      "username": "E-post",
      "zip_code": "Postnummer"
    },
    "home": {
      "business_user": {
        "subtitle": "Bruk appen for å administrere bilen du har tilgang til som ansatt. Hvis du har fått beskjed om å aktivere en bil, følg lenken i e-posten.",
        "title": "Du er ikke administrator for en bedrift"
      },
      "private_user": {
        "link_text": "Til min side",
        "subtitle": "Det var kanskje ikke hit du skulle? For å administrere ditt private abonnement må du logge inn på min side, følg lenken under",
        "title": "Du har ikke en bedriftskonto"
      }
    },
    "home_delivery_costs": {
      "explanation": "*Den ansatte får mulighet til å velge hjemlevering når hen aktiverer bilen. Dersom den ansatte ønsker hjemlevering vil kostnaden komme på første faktura.",
      "name": "Mulighet for hjemlevering*",
      "price": "{price, number, whole},-"
    },
    "how_it_works": {
      "activate_cars": {
        "body": "Finn bilene du kan velge mellom i '<span class=\"font-bold\">'Bilparken'</span>'. Velg den bilen du ønsker å bestille, gå igjennom bestillingsløpet, legg til en ansatt som skal disponere bilen og velg når abonnementet skal starte.",
        "heading": "Bestill biler"
      },
      "add_employees": {
        "body": "Før du bestiller biler må du legge til de ansatte som skal disponere dem. Gå til '<span class=\"font-bold\">'Ansatte'</span>' i menyen på venstre side og trykk '<span class=\"font-bold\">'Legg til ansatt'</span>'.",
        "heading": "Legg til ansatte"
      },
      "contact_us": "Ta kontakt",
      "invoices": {
        "body": "Få oversikt over alle betalte og ikke betalte fakturaer ved å gå til '<span class=\"font-bold\">'Fakturaer'</span>.",
        "heading": "Fakturaer",
        "inline_link_text": "Fakturaer."
      },
      "questions": "Noe du lurer på?",
      "title": "Slik fungerer Bedriftsportalen"
    },
    "invoice_status": {
      "due": "Ikke forfalt",
      "paid": "Betalt",
      "pastDue": "Forfalt"
    },
    "invoices": {
      "list": {
        "date": "{date, date, twoDigit}",
        "description": "Fleksbil og andre tjenester",
        "total": {
          "label": "Totalbeløp",
          "value": "{value, number, NOK}"
        }
      },
      "modal": {
        "amount": "Antall",
        "close": "Lukk",
        "date": {
          "label": "Dato",
          "singleValue": "{date, date, twoDigit}",
          "value": "{from, date, twoDigit}-{to, date, twoDigit}"
        },
        "description": "Beskrivelse",
        "price": {
          "label": "Beløp",
          "value": "{value, number, NOK}"
        },
        "reg_num": "Regnr.",
        "tax": {
          "label": "MVA",
          "value": "{value} %"
        },
        "total": {
          "label": "Totalbeløp",
          "value": "{value, number, NOK}"
        }
      },
      "no_invoices": "Ingen fakturaer",
      "paid": "Betalte",
      "title": "Fakturaer",
      "unpaid": "Ubetalte"
    },
    "login": {
      "fields": {
        "password": "Passord",
        "password_confirmation": "Gjenta passord",
        "username": "E-post"
      },
      "no_user": "Ingen bruker?",
      "placeholder": {
        "login": "navn@epost.no",
        "password": "sterkt passord"
      },
      "reset_password": "Glemt passord?",
      "submit": "{submitting, select, true {Logger inn} other {Logg inn} }",
      "title": "Logg inn"
    },
    "logout": "Logg ut",
    "modify_order": {
      "delivery_option": {
        "home": {
          "address": "Hvilken adresse vil du ha bilen levert til?",
          "subtitle": "Vi bringer bilen til adressen du velger til avtalt tidspunkt.",
          "title": "Hjemlevering"
        },
        "pickup": {
          "address": "{address}",
          "pickup_title": "Du henter bilen her",
          "subtitle": "Dra til Fleks sitt utleveringspunkt for å hente bilen.",
          "title": "Hente selv"
        },
        "subtitle": "Du kan velge selv om du ønsker å dra til utleveringspunktet for å hente bilen, eller få bilen levert til en valgfri adresse.",
        "title": "Hvordan ønsker du å motta bilen?"
      },
      "delivery_time": {
        "submit": "Send bekreftelse",
        "subtitle": "Du kan velge å hente bilen den dagen abonemmentet starter, eller opp til fem virkedager etter.",
        "time_title": "Tidspunkt",
        "title": "Når ønsker du å hente bilen?"
      }
    },
    "modify_order_confirmation": {
      "body": "I mellomtiden kan du laste ned fleksappen. Appen vil fungere som din digitale nøkkel, i tillegg foregår inn- og utleveringsprosessen her. Vi anbefaler at du laster ned appen og logger deg inn i god tid før du mottar bilen.",
      "frontpage": "Til forsiden",
      "title": "Du kan snart hente bilen!"
    },
    "modify_order_entrypoint": {
      "body": "Administrator for bedriften har bestilt en bil til deg og valgt følgende leveringstid. Du kan endre denne ved å følge linken under.",
      "delivery_info": "Utleveringsinfo",
      "links": {
        "abort": "Avbryt",
        "change": "Endre"
      },
      "not_found": {
        "subtitle": "Prøv igjen eller kontakt kundeservice.",
        "title": "Fant ikke bestillingen"
      },
      "past_due": {
        "subtitle": "Det er for kort tid til utlevering av bilen til at du kan endre dato eller tidspunkt.",
        "title": "Denne bestillingen kan ikke endres"
      },
      "submit": "Bekreft",
      "title": "En bil har blitt bestilt til deg!"
    },
    "monthly_costs_table": {
      "included": "inkludert",
      "price": "{price, number, whole},-/mnd",
      "total": "Totalt"
    },
    "nav": {
      "back_to_fleks": "Tilbake til fleks.no",
      "business": "Bedrift",
      "cars": "Bilpark",
      "company_info": "Om bedriften",
      "dashboard": "Dashboard",
      "employees": "Ansatte",
      "invoices": "Fakturaer",
      "login": "Logg inn",
      "sign_out": "Logg ut"
    },
    "no_user": {
      "cancel": "Avbryt",
      "contact": "Ta kontakt",
      "subtitle": "Ta kontakt med oss så setter vi oppe en plan for din bedrift",
      "title": "Ønsker du å bli bedriftskunde?"
    },
    "not_found": {
      "message": "Sjekk linken eller prøv igjen senere.",
      "subtitle": "Siden du prøvde å besøke finnes ikke.",
      "title": "404"
    },
    "order": {
      "abort": {
        "body": "Er du sikker på at du vil avbryte bestillingen?",
        "body_change": "Er du sikker på at du vil avbryte endringen?",
        "cancel": "Nei, fortsett bestillingen",
        "confirm": "Ja",
        "title": "Avbryt"
      },
      "common": {
        "back": "Tilbake",
        "exit": "Avbryt",
        "next": "Gå videre"
      }
    },
    "order_summary": {
      "costs": {
        "price_per_month": "{price, number, whole},-/mnd",
        "title": "Faste kostnader"
      },
      "delivery": {
        "title": "Utlevering"
      },
      "one_time": {
        "price": "{price, number, whole},-",
        "title": "Engangssum"
      }
    },
    "page_titles": {
      "accept_invite": "Opprett Bruker",
      "admin": "Administrator",
      "application": "Fleks Bedrift",
      "confirm_new_password": "Velg nytt passord",
      "login": "Logg inn",
      "no_user": "Bli bedriftskunde",
      "reset_password": "Glemt passord"
    },
    "price": {
      "included": "inkludert",
      "per_employee": "{amount, number, plain},- per ansatt",
      "per_month": "{amount, number, plain},-/mnd"
    },
    "reset_password": {
      "back_to_login": "Tilbake til innlogging",
      "body": "Har du glemt passordet ditt? Det gjør ingenting! Skriv inn e-posten din under, så sender vi deg en e-post med informasjon om hvordan du kan sette nytt passord.",
      "form": {
        "email": {
          "label": "E-post",
          "placeholder": "E-post"
        },
        "submit_button": "Send meg instruksjoner"
      },
      "success_text": "Vi har sendt deg en e-post med instruksjoner.",
      "title": "Glemt passord"
    },
    "selection": {
      "from_price_per_month": "Fra {price, number, whole},-/mnd",
      "included": "inkludert",
      "price": "{price, number, whole},-",
      "price_per_month": "{price, number, whole},-/mnd",
      "select": "{selected, select, true {Valgt} other {Velg} }"
    },
    "signup": {
      "errors": {
        "default_error": "Oi! Det har skjedd en feil. Sjekk at brukernavn er riktig. Om feilen vedvarer kontakt Fleks teamet",
        "not_accepted_terms": "Du må akseptere betingelsene før du kan registrere deg"
      },
      "first_name": "Fornavn",
      "last_name": "Etternavn",
      "login_button": "Logg inn",
      "login_info": "Hvis du allerede har en bruker?",
      "placeholder": {
        "email": "navn@epost.no",
        "password": "sterkt passord",
        "password_confirmation": "gjenta sterkt passord"
      },
      "submit": "{submitting, select, true {Oppretter} other {Opprett profil} }",
      "subtitle": "Registrer deg for å bestille bil &#x1F697 &#x1F4A8",
      "title": "Ny til Fleks?"
    }
  }]];
  _exports.default = _default;
});
define("ember-svg-jar/inlined/right-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.175.293a1.189 1.189 0 011.563 0l6.63 6a.934.934 0 010 1.414l-6.63 6c-.432.39-1.131.39-1.563 0a.934.934 0 010-1.414L7.023 7 1.175 1.707a.934.934 0 010-1.414z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "10",
      "height": "14",
      "viewBox": "0 0 10 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
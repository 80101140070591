define("ember-svg-jar/inlined/distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 21H8c-2.8 0-5-2.2-5-5s2.2-5 5-5h9c1.7 0 3-1.3 3-3s-1.3-3-3-3H8c-.6 0-1-.4-1-1s.4-1 1-1h9c2.8 0 5 2.2 5 5s-2.2 5-5 5H8c-1.7 0-3 1.3-3 3s1.3 3 3 3h8c.6 0 1 .4 1 1s-.4 1-1 1z\" fill=\"#29194A\"/><path d=\"M20 22a2 2 0 100-4 2 2 0 000 4z\" fill=\"#FF4A7E\"/><path d=\"M4 6a2 2 0 100-4 2 2 0 000 4z\" fill=\"#29194A\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
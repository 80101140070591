define("ember-svg-jar/inlined/error_1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_1168_60)\"><path d=\"M224.5 114h-68a7 7 0 100 14h68a7 7 0 100-14zM192.58 29.31h-4.16a6 6 0 00-5.89 4.82L166.5 114h48l-16-79.87a5.997 5.997 0 00-5.92-4.82z\" fill=\"#FF4A7E\"/><path d=\"M205.27 68h-29.54l3.21-16h23.12l3.21 16zM211.69 100h-42.38l3.19-16h36l3.19 16z\" fill=\"#FFD6F6\"/><path d=\"M225 128h-81.75a7.752 7.752 0 01-7.75-7.75V68.78C135.5 31.6 106 .58 68.8 0A67.82 67.82 0 000 67.75V186a2 2 0 002 2h56a2 2 0 002-2V73a7.92 7.92 0 017.22-8 7.76 7.76 0 018.28 7.73v52.5A62.743 62.743 0 00138.25 188h86.13c16.34 0 30.16-12.82 30.61-29.16a29.995 29.995 0 00-18.315-28.476A30.018 30.018 0 00225 128z\" fill=\"#29194A\"/><path d=\"M185 176a2.001 2.001 0 01-2-2.17 20.995 20.995 0 0120.89-18.853 20.998 20.998 0 0120.89 18.853 1.993 1.993 0 01-.522 1.525 1.991 1.991 0 01-1.478.645H185z\" fill=\"#fff\"/><path d=\"M185 176a2.001 2.001 0 01-2-2.17 20.995 20.995 0 0120.89-18.853 20.998 20.998 0 0120.89 18.853 1.993 1.993 0 01-.522 1.525 1.991 1.991 0 01-1.478.645H185z\" fill=\"#fff\"/></g><defs><clipPath id=\"clip0_1168_60\"><path fill=\"#fff\" d=\"M0 0h255v188H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "255",
      "height": "188",
      "viewBox": "0 0 255 188",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
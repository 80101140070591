define("ember-svg-jar/inlined/shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.5 29.75V17.5H7v12.25a7 7 0 007 7h14a7 7 0 007-7V17.5h-3.5v12.25a3.5 3.5 0 01-3.5 3.5h-1.75v-3.5a5.25 5.25 0 10-10.5 0v3.5H14a3.5 3.5 0 01-3.5-3.5zm8.75 3.5h3.5v-3.5a1.75 1.75 0 10-3.5 0v3.5z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.275 15.585a3.5 3.5 0 012.459 1.116c.216.232.466.426.75.562.27.13.634.237 1.141.237.67 0 1.288-.319 1.79-.965.514-.66.835-1.589.835-2.534 0-3.252-2.552-5.25-5.25-5.249H22.558c-3.514-.002-7.534-.003-8.555-.001-2.702.003-5.252 2-5.252 5.25 0 .945.321 1.875.834 2.534.503.646 1.12.965 1.791.965.507 0 .87-.107 1.142-.237.283-.136.533-.33.75-.563a3.5 3.5 0 014.979-.15c.469.448 1.28.95 2.754.95s2.286-.502 2.754-.949a3.5 3.5 0 012.521-.966zm.01 3.619c.938.958 2.349 1.796 4.34 1.796 1.95 0 3.52-.988 4.553-2.316C36.196 17.376 36.75 15.68 36.75 14c0-5.481-4.43-8.75-8.75-8.748h-5.442c-3.512-.002-7.536-.003-8.56-.001C9.68 5.256 5.25 8.517 5.25 14c0 1.68.554 3.375 1.572 4.683C7.854 20.012 9.425 21 11.375 21c1.992 0 3.403-.838 4.34-1.797.04-.04.078-.08.115-.12.04.04.082.078.124.116C17.052 20.193 18.685 21 21 21c2.314 0 3.948-.806 5.046-1.8.042-.039.084-.077.124-.116l.115.12z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/logo-dark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M102.337 37.341c1.798 0 3.255-1.488 3.255-3.323s-1.457-3.323-3.255-3.323c-1.798 0-3.255 1.488-3.255 3.323s1.457 3.323 3.255 3.323z\" fill=\"#FF4A7E\"/><path d=\"M33.96 9.07h-4.485v27.875h4.486V9.07zM47.059 17a9.888 9.888 0 00-7.022 2.987 10.306 10.306 0 00-2.926 7.167c0 5.575 4.486 10.155 10.143 10.155 5.46 0 7.996-3.385 7.996-3.385l-2.535-3.225s-1.95 2.428-5.266 2.428c-3.121 0-4.992-1.99-5.462-4.18h14.239a12.73 12.73 0 00.195-2.19C56.42 21.38 52.52 17 47.059 17zm-5.072 8.163c.586-2.19 2.146-3.776 4.877-3.776 2.535 0 4.096 1.593 4.68 3.776h-9.557zM26.23 17.92c.043-.72.043-1.44 0-2.158-.337-3.965-3.14-7.18-7.019-7.18a6.971 6.971 0 00-4.972 2.105 7.267 7.267 0 00-2.065 5.075v5.642H8.406v3.894h3.768v11.646h4.424V25.298h9.65v-3.894h-9.65v-5.54c0-.71.275-1.39.764-1.893a2.603 2.603 0 011.849-.792c1.44 0 2.522 1.326 2.611 2.684.066.901.017 2.06.017 2.06l4.39-.004zM94.61 27.27c-2.367-3.09-5.044-6.205-8.045-9.999H82.1c-.103 4.12-.841 8.14-2.067 11.138-1.133 2.78-2.592 4.609-3.902 4.609-2.558 0-3.725-3.02-5.868-6.876 2.69-2.815 5.158-6.284 6.22-8.87h-4.838c-1.379 2.607-4.976 6.712-7.587 8.41V9.07h-4.504v27.877h4.508v-5.64a19.964 19.964 0 002.908-2.211c2.247 3.649 4.258 8.137 9.164 8.137 3.195 0 5.99-2.535 7.87-7.137.821-2.015 1.322-4.603 1.733-7.159 2.157 2.673 3.674 4.478 5.059 6.415.597.832.573 2.032.049 2.68-.881 1.087-2.674 1.45-4.728.394L84.14 36.03a8.099 8.099 0 004.471 1.389c2.637 0 4.644-1.149 5.784-2.511 1.796-2.151 2.036-5.266.216-7.639z\" fill=\"#29194A\"/>",
    "attrs": {
      "width": "114",
      "height": "46",
      "viewBox": "0 0 114 46",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M41.71 71.484C38.61 44.859 59.145 21 85.95 21c21.395 0 39.663 15.445 42.418 36.662 3.001 23.11 6.147 52.825 6.147 75.838 0 40.585-28.077 75.641-32.434 80.837-.37.44-.895.663-1.47.663h-83.15c-1.637 0-2.612-1.87-1.69-3.224 7.441-10.93 30.744-47.604 30.744-78.276 0-16.243-2.277-40.308-4.805-62.016z\" fill=\"#29194A\"/><rect y=\"107.959\" width=\"34\" height=\"64\" rx=\"17\" transform=\"rotate(-48.92 0 107.959)\" fill=\"#29194A\"/><path d=\"M93.06 106.06c14.449-2.549 24.682-14.958 25.002-29.086.075-3.313-3.103-5.507-6.367-4.931l-47.786 8.43c-3.264.576-5.499 3.725-4.295 6.813 5.135 13.166 18.997 21.323 33.446 18.774z\" fill=\"#fff\"/><rect x=\"113\" width=\"123\" height=\"215\" rx=\"7\" fill=\"#E6F3FF\"/><rect x=\"121\" y=\"12\" width=\"107\" height=\"166\" rx=\"7\" fill=\"#fff\"/><rect x=\"133\" y=\"58\" width=\"76\" height=\"13\" rx=\"6.5\" fill=\"#E6F3FF\"/><rect x=\"133\" y=\"79\" width=\"64\" height=\"13\" rx=\"6.5\" fill=\"#E6F3FF\"/><rect x=\"133\" y=\"98\" width=\"84\" height=\"13\" rx=\"6.5\" fill=\"#E6F3FF\"/><circle cx=\"174.5\" cy=\"195.5\" r=\"11.5\" fill=\"#29194A\"/>",
    "attrs": {
      "width": "236",
      "height": "215",
      "viewBox": "0 0 236 215",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.432 5.632a1.8 1.8 0 00-1.8 1.8v9.134a1.8 1.8 0 001.8 1.8h9.134a1.8 1.8 0 001.8-1.8V7.432a1.8 1.8 0 00-1.8-1.8H7.432zm-3.682 1.8A3.682 3.682 0 017.432 3.75h9.134a3.682 3.682 0 013.681 3.682v9.134a3.682 3.682 0 01-3.681 3.681H7.432a3.682 3.682 0 01-3.682-3.681V7.432z\" fill=\"#29194A\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.432 5.632a1.8 1.8 0 00-1.8 1.8v.304h12.733v-.304a1.8 1.8 0 00-1.799-1.8H7.432zm-3.682 1.8A3.682 3.682 0 017.432 3.75h9.134a3.682 3.682 0 013.681 3.682v1.245c0 .52-.42.941-.94.941H4.69a.941.941 0 01-.941-.94V7.431z\" fill=\"#29194A\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.255 15.652c0-.65.527-1.176 1.176-1.176h.914a1.176 1.176 0 110 2.353h-.914c-.65 0-1.176-.527-1.176-1.177zM6.255 11.999c0-.65.527-1.177 1.176-1.177h.914a1.176 1.176 0 110 2.353h-.914c-.65 0-1.176-.527-1.176-1.176zM10.822 15.652c0-.65.527-1.176 1.177-1.176h.913a1.176 1.176 0 010 2.353h-.914c-.65 0-1.176-.527-1.176-1.177zM14.476 11.999c0-.65.527-1.177 1.176-1.177h.914a1.176 1.176 0 110 2.353h-.913c-.65 0-1.177-.527-1.177-1.176z\" fill=\"#FF4A7E\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
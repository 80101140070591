define("ember-media/components/media", ["exports", "@ember/component", "ember-media/templates/components/media", "ember-media/components/-private/media-query-list"], function (_exports, _component, _media, _mediaQueryList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _media.default,
    tagName: '',
    matches: true,
    targetWindow: undefined,

    updateMatches() {
      const {
        matches
      } = this.mediaQueryList;
      this.set('matches', matches);

      if (this.onChange) {
        this.onChange(matches);
      }
    },

    didReceiveAttrs() {
      const targetWindow = this.targetWindow || window;
      this.mediaQueryList = new _mediaQueryList.default(targetWindow, this.query, this.updateMatches.bind(this));
      this.updateMatches();
    },

    willDestroyElement() {
      this.mediaQueryList.cancel();
    }

  });

  _exports.default = _default;
});
define("ember-svg-jar/inlined/happy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M113.989 88.66a46.09 46.09 0 0023.36-20.643 45.818 45.818 0 004.818-30.736 45.939 45.939 0 00-15.93-26.756A46.255 46.255 0 0096.853 0a46.254 46.254 0 00-29.385 10.525 45.94 45.94 0 00-15.93 26.756 45.822 45.822 0 004.818 30.736 46.09 46.09 0 0023.36 20.643l-16.482 38.426a1.365 1.365 0 00.6 1.743c.198.109.42.168.647.171h64.743a1.386 1.386 0 001.152-.617 1.365 1.365 0 00.116-1.297L113.989 88.66z\" fill=\"#FF4A7E\"/><path d=\"M58.91 49.654a20.682 20.682 0 00-11.143-11.246 20.87 20.87 0 00-15.881-.123 20.689 20.689 0 00-11.317 11.073 20.501 20.501 0 00-.123 15.782c2.29 5.615 1.784 7.368-3.583 17.052L15.93 69.71a7.908 7.908 0 00-2.735-5.422A8.007 8.007 0 007.4 62.376a8.007 8.007 0 00-3.001.819 7.962 7.962 0 00-2.457 1.898A7.872 7.872 0 00.02 70.851l1.383 19.15a7.911 7.911 0 002.439 5.177 8.007 8.007 0 005.308 2.204A79.49 79.49 0 002.711 129h41.5c0-10.757 3.728-17.438 8.439-25.899 6.854-12.269 16.191-29.088 6.26-53.447zM118.254 52.618a1.39 1.39 0 011.308.914c.064.178.09.366.076.554a22.555 22.555 0 01-7.174 15.046 22.94 22.94 0 01-15.611 6.118 22.942 22.942 0 01-15.612-6.118 22.558 22.558 0 01-7.174-15.046 1.36 1.36 0 01.37-1.032 1.383 1.383 0 011.014-.436h42.803z\" fill=\"#29194A\"/><path d=\"M53.52 56.105a1.363 1.363 0 011.542.402c.122.146.212.316.265.5A14.508 14.508 0 0154.28 67.33a14.403 14.403 0 01-7.666 6.956 14.316 14.316 0 01-10.33-.002 14.403 14.403 0 01-7.662-6.96 1.382 1.382 0 01.74-1.885l24.156-9.335z\" fill=\"#fff\"/><path d=\"M117.587 101.881c3.114 3.093 8.161 3.094 11.274.003a7.88 7.88 0 00-.002-11.196l-3.245-3.222c-3.113-3.092-8.161-3.093-11.273-.002a7.877 7.877 0 00.002 11.195l3.244 3.222z\" fill=\"#29194A\"/>",
    "attrs": {
      "width": "143",
      "height": "129",
      "viewBox": "0 0 143 129",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/logo-dark_no_padding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FF4A7E\" d=\"M558.6 167.6a19.4 19.4 0 100-38.8 19.4 19.4 0 000 38.8z\"/><path fill=\"#29194A\" d=\"M152 2.8h-26.7v162.4H152V2.8zm77.9 46.4a59.4 59.4 0 00-59.2 59.2 59.7 59.7 0 0060.3 59.2c32.5 0 47.6-19.8 47.6-19.8l-15-18.8s-11.7 14.2-31.4 14.2c-18.6 0-29.7-11.6-32.5-24.4h84.7a72.7 72.7 0 001.2-12.7 55.2 55.2 0 00-55.7-56.9zm-30.2 47.6a28 28 0 0129-22c15.1 0 24.4 9.3 27.9 22h-56.9zM106 54.4c.3-4.2.3-8.4 0-12.6C104 18.8 87.3 0 64.3 0a41.9 41.9 0 00-41.9 41.8v32.9H0v22.7h22.4v67.8h26.3V97.4h57.4V74.7H48.7V42.4a15.6 15.6 0 0115.6-15.6c8.5 0 15 7.7 15.5 15.6.4 5.3 0 12 0 12H106zm406.7 54.5c-14.1-18-30-36.2-47.9-58.3h-26.5c-.6 24-5 47.4-12.3 65-6.7 16.1-15.4 26.8-23.2 26.8-15.2 0-22.2-17.6-35-40.1a190 190 0 0037-51.7h-28.7a166.3 166.3 0 01-45.1 49V2.8h-26.8v162.4H331v-32.8a118.6 118.6 0 0017.3-12.9c13.4 21.3 25.3 47.4 54.5 47.4 19 0 35.6-14.8 46.8-41.6 4.9-11.7 7.9-26.8 10.3-41.7 12.8 15.6 21.9 26.1 30.1 37.4 3.5 4.8 3.4 11.8.3 15.6-5.2 6.4-16 8.5-28.1 2.3l-11.8 21A48.9 48.9 0 00477 168c15.7 0 27.6-6.7 34.4-14.6a34.7 34.7 0 001.3-44.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 578 168"
    }
  };
  _exports.default = _default;
});
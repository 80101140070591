define("ember-svg-jar/inlined/map-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.5 0C8.538 0 11 2.66 11 5.94c0 2.025-1.57 5.048-4.712 9.07a1 1 0 01-1.576 0l-.29-.374C1.474 10.8 0 7.901 0 5.94 0 2.66 2.462 0 5.5 0zm0 3a2.5 2.5 0 100 5 2.5 2.5 0 000-5z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "11",
      "height": "16",
      "viewBox": "0 0 11 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/error_2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_1168_69)\"><path d=\"M50 195c14.912 0 27-12.088 27-27s-12.088-27-27-27-27 12.088-27 27 12.088 27 27 27z\" fill=\"#29194A\"/><path d=\"M255.76 135.23l-11.92-1.37L229.47 73a20.002 20.002 0 00-17.18-15.27l-88.6-10.19a20.002 20.002 0 00-18.4 8l-32.7 44.48-15.9-1.83a9 9 0 00-9 13l-14.42-1.65a29.994 29.994 0 00-22.13 6.321A29.996 29.996 0 000 136a30.002 30.002 0 0026.37 33.23l222.54 25.6A9.999 9.999 0 00260 186l4.58-39.74a10 10 0 00-8.82-11.03z\" fill=\"#FF4A7E\"/><path d=\"M87.805 134.01l-31.79-3.655c-6.585-.758-12.536 3.966-13.293 10.55-.757 6.584 3.966 12.535 10.55 13.292l31.79 3.656c6.585.757 12.536-3.966 13.293-10.55.757-6.584-3.966-12.535-10.55-13.293z\" fill=\"#29194A\"/><path d=\"M24.84 150.86c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12-6.628 0-12 5.373-12 12 0 6.628 5.372 12 12 12z\" fill=\"#fff\"/><path d=\"M116.23 161.37c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12s-12 5.373-12 12c0 6.628 5.373 12 12 12z\" fill=\"#29194A\"/><path d=\"M62.91 113l38.32-52 66.11 7.61a1.997 1.997 0 011.765 2.206 2 2 0 01-.375.964l-33.4 45.45a8.002 8.002 0 01-7.37 3.21L62.91 113zM149.4 120l34.89-47.32a4.001 4.001 0 013.68-1.6l26.69 3.07a4.002 4.002 0 013.44 3.07l12.19 52.41a2.004 2.004 0 01-.456 1.786 1.995 1.995 0 01-1.724.654l-77.33-8.9a2.005 2.005 0 01-1.602-1.188A2.007 2.007 0 01149.4 120z\" fill=\"#FFD6F6\"/><path d=\"M230 177h-18a9 9 0 00-9 9 9 9 0 009 9h18a9 9 0 009-9 9 9 0 00-9-9zM177 54c14.912 0 27-12.088 27-27S191.912 0 177 0s-27 12.088-27 27 12.088 27 27 27z\" fill=\"#29194A\"/><path d=\"M181.24 27l7.38-7.38a2.998 2.998 0 00-.074-4.166 2.998 2.998 0 00-4.166-.074L177 22.76l-7.38-7.38a3 3 0 00-4.24 4.24l7.38 7.38-7.38 7.38a3 3 0 000 4.24 3 3 0 004.24 0l7.38-7.38 7.38 7.38a3 3 0 004.24-4.24L181.24 27z\" fill=\"#fff\"/></g><defs><clipPath id=\"clip0_1168_69\"><path fill=\"#fff\" d=\"M0 0h265v195H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "265",
      "height": "195",
      "viewBox": "0 0 265 195",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-2{fill:#fff}</style></defs><circle cx=\"608.64\" cy=\"198.2\" r=\"19.36\" fill=\"#ff4a7e\"/><path class=\"cls-2\" d=\"M175.3 52.84h26.68v162.4H175.3zM279.88 99.24a59.41 59.41 0 00-59.16 59.16c0 32.48 26.68 59.16 60.32 59.16 32.48 0 47.56-19.72 47.56-19.72l-15.08-18.79s-11.6 14.15-31.32 14.15c-18.56 0-29.69-11.6-32.48-24.36h84.68a72.71 72.71 0 001.16-12.76c0-31.32-23.2-56.84-55.68-56.84zm-30.16 47.56c3.48-12.76 12.76-22 29-22 15.08 0 24.36 9.28 27.84 22zM156 104.4a102.09 102.09 0 000-12.57C154 68.73 137.33 50 114.26 50a41.89 41.89 0 00-41.85 41.83v32.87H50v22.69h22.41v67.85h26.31v-67.85h57.39V124.7H98.72V92.42a15.64 15.64 0 0115.54-15.64c8.56 0 15 7.73 15.53 15.64.39 5.25.1 12 .1 12zM562.68 158.87c-14.08-18-30-36.15-47.85-58.25h-26.55c-.61 24-5 47.42-12.29 64.89-6.74 16.2-15.42 26.85-23.21 26.85-15.21 0-22.15-17.59-34.9-40.06 16-16.4 30.68-36.61 37-51.68H426.1c-8.2 15.19-29.59 39.11-45.12 49V52.84h-26.79v162.41H381v-32.86a118.52 118.52 0 0017.3-12.88c13.36 21.26 25.32 47.41 54.5 47.41 19 0 35.62-14.77 46.8-41.58 4.89-11.74 7.87-26.82 10.31-41.71C522.74 149.2 531.76 159.72 540 171c3.55 4.85 3.41 11.84.29 15.62-5.24 6.33-15.9 8.44-28.12 2.29l-11.76 21A48.87 48.87 0 00527 218c15.68 0 27.62-6.69 34.4-14.63 10.68-12.53 12.11-30.68 1.28-44.5z\"/>",
    "attrs": {
      "id": "Logo",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 678 268"
    }
  };
  _exports.default = _default;
});